@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --box-shadow-highlight-soft: 0px 2px 8px 0px rgba(0, 0, 0, 0.1),
      0px 1px 0px 0px var(--color-white-010) inset;
    --box-shadow-highlight-hard: 0px 2px 8px 0px rgba(0, 0, 0, 0.1),
      0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  }

  .dark {
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
